export const allowlistAddresses = [

["0x0e4d4e4a9f088a1ea9bceac9e897330f5b2e1247",3],
["0xf20837106213a6366596179b7eeed81d22282aa4",3],
["0x0be0d1cca528c3870e4187e2bde9b3861b12f622",3],
["0xd6c07b167c96dcfe62829d2f900c2bb5119d564e",3],
["0xcc1f01220b9d9a2bcabb87da9cbc75e2b8a06b8b",3],
["0x0b122c9d7b1e0dc305feb4cbfe97646d02a10bc6",3],
["0x391525cb80b3d25fd7dfd0a0ec51d69b567fcb90",3],
["0x9740e07b73d3b9ca24b77015b38ea18205c07734",3],
["0x3604db07f0a3c46a7437464297d3f13ae5d04f4f",3],
["0x11dd935d65dbc8425e8ba1d9ce4d85e8e6000737",5],
["0xef3025c71fade81a5e21ca1a468c5bc6a6f1ea32",5],
["0xe40850f4da5b96bc4d2a5041bdbfba15e4b13c3f",5],
["0xe693f386a965d8f87b72e43e46e756b362232253",5],
["0x8b2e6232bf4884a28464706f1ecec95ad42e9b1d",5],
["0x06bac69d925002b5b1e5553b8c20f997144905c0",5],
["0x842ce79e791fb9a2bc8cd6b1904d591bf6e59a21",5],
["0xa1a45a2b5fcdcdc590f5203d194e563ae6742e32",5],
["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",10],
["0xc06aabad85ecccfa35cfe671d6df90ac9d46619b",10],
["0xe82921cad4f21116e3ab1b09ae0fa161a4135329",10],
["0x7dea578b1b46bcca873251eb54a0543b46b749a6",10],
["0x26baa1ab3f10ee51adbffd9069b4545ef0ee5c95",10],
["0xa5acf68b1ff6e33381797914a906d3797e47ce6c",10],
["0x8878417c2766fe57fa265d73e8ce42b2bc4af5ac",10],
["0x71926c86a0f06d17fd0b743ffafa3f9f53613998",10],
["0x243119dbef6acffe4420c2b49f7a3ec2f8f870f5",15],

]